<template>
  <div class="row-labels">
    <div
      v-if="noChangeFlg"
      class="label default"
      :class="{ hidden: !showFlg }"
      data-test="no-change"
    >
      変更不可
    </div>
    <div
      v-if="examinationFlg"
      class="label default examination"
      :class="{ hidden: !showFlg }"
      data-test="examination"
    >
      検査登録可
    </div>
    <div
      v-if="noExaminationFlg"
      class="label default no-examination"
      :class="{ hidden: !showFlg }"
      data-test="no-examination"
    >
      検査基準値未登録
    </div>
    <div v-if="!showFlg" class="label no-show" data-test="no-show">非表示</div>
  </div>
</template>

<script>
export default {
  name: 'RowLabels',
  props: {
    examinationFlg: { type: Boolean, default: false },
    noChangeFlg: { type: Boolean, default: false },
    noExaminationFlg: { type: Boolean, default: false },
    showFlg: { type: Boolean, default: true }
  }
}
</script>

<style lang="scss" scoped>
.row-labels {
  display: flex;
  > .label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    line-height: 18px;
    border-radius: 20px;
    font-size: 12px;
    margin: 2px 0 2px 10px;
    box-sizing: border-box;
    &.default {
      width: 60px;
      color: #{$gray_7};
      border: solid 1px #{$gray_7};
    }
    &.examination {
      width: 75px;
      color: #{$gray_7};
      border: solid 1px #{$gray_7};
    }
    &.no-examination {
      width: 105px;
      color: #{$gray_7};
      border: solid 1px #{$gray_7};
    }
    &.hidden {
      opacity: 0.3;
    }
    &.no-show {
      width: 45px;
      color: #{$greyish-brown};
      border: solid 1px #{$greyish-brown};
    }
  }
}
@media (max-width: $horizontal-tablet-width) {
  .row-labels {
    > .label {
      font-size: 11px;
    }
  }
}
</style>
