<template>
  <div class="slide-list">
    <div class="outside">
      <draggable v-if="sortModeFlg" v-model="orderParents">
        <slide-list-parent
          v-for="(parent, index) in orderParents"
          :key="`${parent.id}-parent`"
          :parent="parent"
          :bottomLayer="bottomLayer"
          :index="index"
          :sortModeFlg="sortModeFlg"
          @order-children="orderChildren"
          @order-grandchildren="orderGrandchildren"
          @order-great-grandchildren="orderGreatGrandchildren"
        />
      </draggable>
      <slide-list-parent
        v-else
        v-for="parent in bodyData"
        :key="`${parent.id}-parent`"
        :ref="`parent${parent.originalId}`"
        :parent="parent"
        :bottomLayer="bottomLayer"
        @add-child="addChild"
        @add-grandchild="addGrandchild"
        @add-great-grandchild="addGreatGrandchild"
        @edit-parent="editParent"
        @edit-child="editChild"
        @edit-grandchild="editGrandchild"
        @edit-great-grandchild="editGreatGrandchild"
        @delete-parent="deleteParent"
        @delete-child="deleteChild"
        @delete-grandchild="deleteGrandchild"
        @delete-great-grandchild="deleteGreatGrandchild"
        @select-child="selectChild"
        @select-grandchild="selectGrandchild"
        @select-great-grandchild="selectGreatGrandchild"
      />
    </div>
  </div>
</template>

<script>
import SlideListParent from '@/components/parts/molecules/SlideListParent'
import Draggable from 'vuedraggable'

export default {
  name: 'SlideList',

  components: {
    SlideListParent,
    Draggable
  },

  props: {
    bodyData: {
      id: { type: Number },
      originalId: { type: Number },
      name: { type: String },
      noChangeFlg: { type: Boolean, default: false },
      plusFlg: { type: Boolean, default: false },
      children: { type: Array, default: () => [] }
    },
    bottomLayer: { type: String, default: '' },
    sortModeFlg: { type: Boolean, default: false }
  },

  computed: {
    orderParents: {
      get() {
        return this.bodyData
      },
      set(newOrderParents) {
        this.$emit('order-parents', newOrderParents)
      }
    }
  },
  methods: {
    orderChildren(data) {
      this.$emit('order-children', data)
    },
    orderGrandchildren(data) {
      this.$emit('order-grandchildren', data)
    },
    orderGreatGrandchildren(data) {
      this.$emit('order-great-grandchildren', data)
    },
    addChild(parentId) {
      this.$emit('add-child', parentId)
    },
    addGrandchild(childId) {
      this.$emit('add-grandchild', childId)
    },
    addGreatGrandchild(grandchildId) {
      this.$emit('add-great-grandchild', grandchildId)
    },
    editParent(parentOriginalId) {
      this.$emit('edit-parent', parentOriginalId)
    },
    editChild(childOriginalId) {
      this.$emit('edit-child', childOriginalId)
    },
    editGrandchild(grandchildOriginalid) {
      this.$emit('edit-grandchild', grandchildOriginalid)
    },
    editGreatGrandchild(greatGrandchildOriginalId) {
      this.$emit('edit-great-grandchild', greatGrandchildOriginalId)
    },
    deleteParent(parentId) {
      this.$emit('delete-parent', parentId)
    },
    deleteChild(childId) {
      this.$emit('delete-child', childId)
    },
    deleteGrandchild(grandchildId) {
      this.$emit('delete-grandchild', grandchildId)
    },
    deleteGreatGrandchild(greatGrandchildId) {
      this.$emit('delete-great-grandchild', greatGrandchildId)
    },
    selectChild(childOriginalId) {
      this.$emit('select-child', childOriginalId)
    },
    selectGrandchild(grandchildId) {
      this.$emit('select-grandchild', grandchildId)
    },
    selectGreatGrandchild(greatGrandchildOriginalId) {
      this.$emit('select-great-grandchild', greatGrandchildOriginalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-list {
  max-height: 650px;
  box-sizing: border-box;
  overflow-y: scroll;
  font-size: 15px;
  border: 1px solid #{$very-light-grey};
  > .outside {
    height: 100%;
  }
}
@media (max-width: $horizontal-tablet-width) {
  > .slide-list {
    width: 612px;
  }
}
</style>
