<template>
  <div class="slide-list-grandchild">
    <div
      class="grandchild"
      v-if="grandchildPanelShowFlg"
      @click="clickGrandchild(grandchild.originalId)"
    >
      <div :class="{ frame: true, sort: sortModeFlg }" data-test="grandchild">
        <div
          class="image-allow"
          :class="{ hide: grandchild.showFlg === 0 }"
          v-if="bottomLayer !== 'grandchild'"
        >
          <img
            class="bottom-allow"
            src="@/assets/images/allow3.png"
            v-show="greatGrandchildPanelShowFlg"
          />
          <img
            class="right-allow"
            src="@/assets/images/allow4.png"
            v-show="!greatGrandchildPanelShowFlg"
          />
        </div>
        <div
          class="name"
          :class="{
            hide: grandchild.showFlg === 0,
            margin: bottomLayer === 'grandchild'
          }"
          :title="grandchild.name"
        >
          {{ grandchild.name }}
        </div>
        <div
          class="price"
          :class="{ hide: grandchild.showFlg === 0 }"
          v-if="price !== ''"
          data-test="price"
        >
          {{ price }}
        </div>
        <div
          class="tax"
          :class="{ hide: grandchild.showFlg === 0 }"
          v-if="taxExemptFlg !== ''"
          data-test="tax"
        >
          {{ taxExemptFlg }}
        </div>
        <div
          class="anicom"
          :class="{ hide: grandchild.showFlg === 0 }"
          v-if="anicomFlg !== ''"
          data-test="anicom"
        >
          {{ anicomFlg }}
        </div>
        <div
          class="ipet"
          :class="{ hide: grandchild.showFlg === 0 }"
          v-if="ipetFlg !== ''"
          data-test="ipet"
        >
          {{ ipetFlg }}
        </div>
        <div class="labels" v-if="!sortModeFlg">
          <row-labels
            :noChangeFlg="grandchild.noChangeFlg"
            :showFlg="Boolean(grandchild.showFlg)"
            :examinationFlg="Boolean(grandchild.examinationFlg)"
            :noExaminationFlg="Boolean(grandchild.noExaminationFlg)"
          />
        </div>
      </div>
      <div
        class="buttons"
        v-if="
          !grandchild.noChangeFlg &&
            !sortModeFlg &&
            bottomLayer !== 'grandchild'
        "
      >
        <base-button-plus
          v-if="lookOnlyFlg === 0"
          class="add"
          :text="'項目追加'"
          :styles="{ width: '106px' }"
          @click="addGreatGrandchild"
        />
        <base-button-trash
          v-if="lookOnlyFlg === 0"
          class="trash"
          :styles="buttonStyles"
          @click.stop="deleteGrandchild"
        />
        <base-button-small-white
          v-if="lookOnlyFlg === 0"
          :styles="buttonStyles"
          @click="editGrandchild"
          >編集</base-button-small-white
        >
      </div>
    </div>
    <div class="great-grandchildren" v-if="bottomLayer !== 'grandchild'">
      <draggable v-if="sortModeFlg" v-model="orderGreatGrandchildren">
        <medicine-detail-list
          v-for="(greatGrandchild, index) in grandchild.greatGrandchildren"
          :key="greatGrandchild.id"
          :medicine="greatGrandchild"
          :showFlg="greatGrandchildPanelShowFlg"
          :isLast="
            index + 1 === grandchild.greatGrandchildren.length ? true : false
          "
        />
      </draggable>
      <medicine-detail-list
        v-else
        v-for="(greatGrandchild, index) in grandchild.greatGrandchildren"
        :key="greatGrandchild.id"
        :medicine="greatGrandchild"
        :showFlg="greatGrandchildPanelShowFlg"
        :isLast="
          index + 1 === grandchild.greatGrandchildren.length ? true : false
        "
        @click.native="selectGreatGrandchild(greatGrandchild.originalId)"
      />
    </div>
  </div>
</template>

<script>
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import MedicineDetailList from '@/components/parts/molecules/MedicineDetailList'
import RowLabels from '@/components/parts/atoms/RowLabels'
import Draggable from 'vuedraggable'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import { mapGetters } from 'vuex'

export default {
  name: 'SlideListGrandchild',

  components: {
    BaseButtonPlus,
    BaseButtonTrash,
    BaseButtonSmallWhite,
    MedicineDetailList,
    RowLabels,
    Draggable
  },

  mixins: [DetectWindowSize],

  props: {
    grandchild: {
      id: { type: Number, default: 0 },
      originalId: { type: Number, default: 0 },
      name: { type: String, default: '' },
      price: { type: Number, default: 0 },
      taxExemptFlg: { type: Number, default: 0 },
      anicomFlg: { type: Boolean, default: false },
      ipetFlg: { type: Boolean, default: false },
      greatGrandchildren: { type: Array, default: [] },
      noChangeFlg: { type: Boolean, default: false }
    },
    bottomLayer: { type: String, default: '' },
    index: { type: Number, default: 0 },
    grandchildPanelShowFlg: { type: Boolean, default: false },
    sortModeFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      greatGrandchildPanelShowFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    orderGreatGrandchildren: {
      get() {
        return this.grandchild.greatGrandchildren
      },
      set(newOrderGreatGrandchildren) {
        this.$emit('order-great-grandchildren', {
          grandchildIndex: this.index,
          newOrderGreatGrandchildren
        })
      }
    },
    price() {
      return this.grandchild.price !== undefined
        ? `￥${this.grandchild.price.toLocaleString()}`
        : ''
    },
    taxExemptFlg() {
      return this.grandchild.taxExemptFlg === 1
        ? '非課税'
        : this.grandchild.taxExemptFlg === 0
        ? '課税'
        : ''
    },
    anicomFlg() {
      return this.grandchild.anicomFlg === 1
        ? 'アニコム保険 〇'
        : this.grandchild.anicomFlg === 0
        ? 'アニコム保険 ×'
        : ''
    },
    ipetFlg() {
      return this.grandchild.ipetFlg === 1
        ? 'アイペット保険 〇'
        : this.grandchild.ipetFlg === 0
        ? 'アイペット保険 ×'
        : ''
    },
    buttonStyles() {
      return this.mixinWindowWidth > 768
        ? { width: '70px', height: '33px' }
        : { width: '44px', height: '33px' }
    }
  },

  watch: {
    grandchildPanelShowFlg: function() {
      if (this.grandchildPanelShowFlg === false)
        this.greatGrandchildPanelShowFlg = false
    }
  },

  methods: {
    clickGrandchild(grandchildOriginalId) {
      if (this.bottomLayer === 'grandchild') {
        this.$emit('select-grandchild', grandchildOriginalId)
      } else {
        this.greatGrandchildPanelShowFlg = !this.greatGrandchildPanelShowFlg
      }
    },
    addGreatGrandchild() {
      this.$emit('add-great-grandchild', this.grandchild.id)
    },
    deleteGrandchild() {
      this.$emit('delete-grandchild', this.grandchild.id)
    },
    editGrandchild() {
      this.$emit('edit-grandchild', this.grandchild.originalId)
    },
    selectGreatGrandchild(greatGrandchildOriginalId) {
      this.$emit('select-great-grandchild', greatGrandchildOriginalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-list-grandchild {
  > .grandchild {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #{$very-light-grey};
    background-color: #{$gray_ea};
    height: 55px;
    box-sizing: border-box;
    @include hover();
    > .frame {
      width: 100%;
      display: flex;
      align-items: center;
      height: 100%;
      > .image-allow {
        width: 16px;
        margin-left: 40px;
        > .bottom-allow {
          width: 15px;
          height: 9px;
        }
        > .right-allow {
          margin-top: 6px;
          width: 9px;
          height: 15px;
        }
      }
      > .name {
        width: 24%;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > .margin {
        margin-left: 62px;
      }
      > .price {
        width: 15%;
        margin-left: 10px;
      }
      > .tax {
        width: 10%;
        margin: 0 10px;
      }
      > .anicom {
        width: 15%;
        margin: 0 10px;
      }
      > .ipet {
        width: 16%;
      }
      > .labels {
        min-width: 190px;
        width: 20%;
        margin: 0 10px;
      }
    }
    > .sort {
      width: 100%;
      cursor: move;
    }
    > .buttons {
      margin: 0 20px 0 auto;
      display: flex;
      align-items: center;
      gap: 0 10px;
    }
    .hide {
      opacity: 0.3;
    }
  }
}
@media (max-width: 1290px) {
  .slide-list-grandchild {
    > .grandchild {
      > .frame {
        font-size: 14px;
        > .image-allow {
          margin-left: 30px;
        }
        > .margin {
          margin-left: 40px;
        }
        > .name {
          min-width: 190px;
        }
        > .price {
          min-width: 105px;
        }
        > .tax {
          min-width: 90px;
        }
        > .anicom {
          min-width: 105px;
        }
        > .ipet {
          min-width: 120px;
        }
        > .labels {
          min-width: unset;
          height: 100%;
          ::v-deep .row-labels {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
