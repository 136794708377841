<template>
  <div class="medicine-detail-list" v-if="showFlg">
    <div class="wrap" :class="{ last: isLast }">
      <div class="boxes" :class="{ dark: darkMode }">
        <div class="box name">
          <div class="cell upper color" :title="displayMedicine.name">
            <div class="text">
              {{ displayMedicine.name }}
            </div>
          </div>
          <div class="cell under color">
            <div class="text">{{ displayMedicine.unitType }}</div>
          </div>
        </div>
        <div class="box price">
          <div class="cell label color">
            <div class="text">{{ '単価（￥）' }}</div>
          </div>
          <div class="cell value">
            <div class="text number">{{ displayMedicine.priceRate }}</div>
          </div>
        </div>
        <div class="box default">
          <div class="cell label color">
            <div class="text">{{ '初期値' }}</div>
          </div>
          <div class="cell value">
            <div class="text number">{{ displayMedicine.defaultVolume }}</div>
          </div>
        </div>
        <div class="box tax">
          <div class="cell label color">
            <div class="text">{{ '非課税' }}</div>
          </div>
          <div class="cell value">
            <div class="text">{{ displayMedicine.taxExemptFlg }}</div>
          </div>
        </div>
        <div class="box anesthesia">
          <div class="cell label color">
            <div class="text">{{ '麻酔薬' }}</div>
          </div>
          <div class="cell value">
            <div class="text">{{ displayMedicine.anesthesiaFlg }}</div>
          </div>
        </div>
        <div class="box vaccine">
          <div class="cell label color">
            <div class="text">{{ 'ワクチン' }}</div>
          </div>
          <div class="cell value" :title="displayMedicine.vaccine">
            <div class="text">{{ displayMedicine.vaccine }}</div>
          </div>
        </div>
        <div class="box anicom">
          <div class="cell label color">
            <div class="text">
              {{ this.mixinWindowWidth > 1385 ? 'アニコム保険' : 'アニコム' }}
            </div>
          </div>
          <div class="cell value">
            <div class="text">{{ displayMedicine.anicomFlg }}</div>
          </div>
        </div>
        <div class="box ipet">
          <div class="cell label color">
            <div class="text">
              {{
                this.mixinWindowWidth > 1385 ? 'アイペット保険' : 'アイペット'
              }}
            </div>
          </div>
          <div class="cell value">
            <div class="text">{{ displayMedicine.ipetFlg }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toCircleOrCross, toZeroSuppress } from '@/utils/convert_string'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'

export default {
  name: 'MedicineDetailList',

  mixins: [DetectWindowSize],

  props: {
    medicine: { type: Object, required: true },
    showFlg: { type: Boolean, default: false },
    isLast: { type: Boolean, default: false }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById'
    }),
    displayMedicine() {
      const medicine = this.medicine
      const unitType = this.getMasterDatum('unitTypes', medicine.unitTypeId)
      return {
        ...medicine,
        unitType: `（表示単位: ${unitType.name}）`,
        priceRate: toZeroSuppress(medicine.priceRate, true),
        defaultVolume: toZeroSuppress(medicine.defaultVolume, true),
        taxExemptFlg: toCircleOrCross(medicine.taxExemptFlg),
        anesthesiaFlg: toCircleOrCross(medicine.anesthesiaFlg),
        vaccine:
          this.getMasterDatum('vaccines', medicine.vaccineId)?.name ||
          '非ワクチン',
        anicomFlg: toCircleOrCross(medicine.anicomFlg),
        ipetFlg: toCircleOrCross(medicine.ipetFlg)
      }
    },
    darkMode() {
      return this.medicine.showFlg === 0 ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
$box-border-color: #{$light-grey};
.medicine-detail-list {
  background-color: #{$gray_ea};
  padding-left: 40px;
  @include hover();
  > .wrap {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #{$gray_c2};
    &.last {
      border: none;
    }
    > .boxes {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      width: 100%;
      border: solid 1px $box-border-color;
      background-color: #{$white};
      margin: 15px 10px;
      &.dark {
        filter: brightness(0.7);
      }
      > .box {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-left: 1px solid $box-border-color;
        border-right: 1px solid $box-border-color;
        &.name {
          width: 24%;
        }
        &.price {
          width: 12%;
        }
        &.default {
          width: 12%;
        }
        &.tax {
          width: 8%;
        }
        &.anesthesia {
          width: 8%;
        }
        &.vaccine {
          width: 16%;
        }
        &.anicom {
          width: 10%;
        }
        &.ipet {
          width: 10%;
        }
        > .cell {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          &.upper {
            align-items: flex-end;
            font-size: 13px;
            font-weight: bold;
            color: #{$black_6};
          }
          &.under {
            padding-top: 8px;
            align-items: flex-start;
            font-size: 11px;
          }
          &.label {
            font-size: 11px;
            font-weight: bold;
            color: #{$black_6};
          }
          &.value {
            background-color: #{$white};
            font-size: 13px;
          }
          &.color {
            background-color: #{$very-light-shade-orange};
          }
          > .text {
            width: 100%;
            padding: 0 10px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.number {
              text-align: right;
            }
          }
        }
        > .label + .value {
          border-top: 1px solid $box-border-color;
        }
      }
      > .box + .box {
        border-left: 0;
        border-right: 1px solid $box-border-color;
      }
      > .box {
        &:first-child {
          border-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
      }
    }
  }
}
@media (max-width: $tablet-width) {
  .medicine-detail-list {
    > .wrap {
      > .boxes {
        width: 800px;
        > .box {
          &.default {
            width: 10%;
          }
          &.anicom {
            width: 11%;
          }
          &.ipet {
            width: 11%;
          }
        }
      }
    }
  }
}
@media (max-width: $horizontal-tablet-width) {
  .medicine-detail-list {
    > .wrap {
      > .boxes {
        width: 680px;
        > .box {
          &.name {
            width: 28%;
          }
          &.anicom {
            width: 12%;
          }
          &.ipet {
            width: 12%;
          }
        }
      }
    }
  }
}
</style>
