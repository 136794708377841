<template>
  <div class="select-box-text-form">
    <div class="heading">
      {{ label }}
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body">
      <validation-provider
        class="validate"
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          oneOfRule: selectData
        }"
        v-slot="{ errors }"
      >
        <base-select-box
          class="select-box"
          :value="selectValue"
          :selectData="selectData"
          :disabled="selectDisabled"
          :styles="selectStyles"
          v-model="selectInput"
        />
        <div class="error" data-test="select-error">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </validation-provider>
      <validation-provider
        class="validate"
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          notSurrogatePair: true
        }"
        v-slot="{ errors }"
      >
        <base-text-box
          :value="textValue"
          :maxlength="maxlength"
          :placeholder="placeholder"
          :disabled="textDisabled"
          :styles="textStyles"
          :type="'text'"
          v-model="textInput"
        />
        <div class="error" data-test="text-error">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'SelectBoxTextForm',

  components: {
    BaseTextBox,
    BaseSelectBox,
    ValidationProvider
  },

  props: {
    label: { type: String },
    maxlength: { type: Number, default: 255 },
    placeholder: { type: String, default: '' },
    requiredFlg: { type: Boolean, default: false },
    selectData: { type: Array },
    selectValue: { type: [String, Number] },
    selectDisabled: { type: Boolean, default: false },
    selectStyles: { type: Object },
    textDisabled: { type: Boolean, default: false },
    textStyles: { type: Object },
    textValue: { type: String, default: '' },
    validationRules: { type: Object }
  },

  computed: {
    selectInput: {
      get() {
        return this.selectValue
      },
      set(val) {
        this.$emit('select-input', val)
      }
    },
    textInput: {
      get() {
        return this.textValue
      },
      set(val) {
        this.$emit('text-input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-box-text-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 240px;
    font-size: 15px;
    > .text {
      display: flex;
    }
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    display: flex;
    align-items: flex-start;
    @include validate-message();
    .select-box {
      margin-right: 10px;
    }
  }
}
</style>
