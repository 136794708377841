var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slide-list-grandchild"},[(_vm.grandchildPanelShowFlg)?_c('div',{staticClass:"grandchild",on:{"click":function($event){return _vm.clickGrandchild(_vm.grandchild.originalId)}}},[_c('div',{class:{ frame: true, sort: _vm.sortModeFlg },attrs:{"data-test":"grandchild"}},[(_vm.bottomLayer !== 'grandchild')?_c('div',{staticClass:"image-allow",class:{ hide: _vm.grandchild.showFlg === 0 }},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.greatGrandchildPanelShowFlg),expression:"greatGrandchildPanelShowFlg"}],staticClass:"bottom-allow",attrs:{"src":require("@/assets/images/allow3.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.greatGrandchildPanelShowFlg),expression:"!greatGrandchildPanelShowFlg"}],staticClass:"right-allow",attrs:{"src":require("@/assets/images/allow4.png")}})]):_vm._e(),_c('div',{staticClass:"name",class:{
          hide: _vm.grandchild.showFlg === 0,
          margin: _vm.bottomLayer === 'grandchild'
        },attrs:{"title":_vm.grandchild.name}},[_vm._v(" "+_vm._s(_vm.grandchild.name)+" ")]),(_vm.price !== '')?_c('div',{staticClass:"price",class:{ hide: _vm.grandchild.showFlg === 0 },attrs:{"data-test":"price"}},[_vm._v(" "+_vm._s(_vm.price)+" ")]):_vm._e(),(_vm.taxExemptFlg !== '')?_c('div',{staticClass:"tax",class:{ hide: _vm.grandchild.showFlg === 0 },attrs:{"data-test":"tax"}},[_vm._v(" "+_vm._s(_vm.taxExemptFlg)+" ")]):_vm._e(),(_vm.anicomFlg !== '')?_c('div',{staticClass:"anicom",class:{ hide: _vm.grandchild.showFlg === 0 },attrs:{"data-test":"anicom"}},[_vm._v(" "+_vm._s(_vm.anicomFlg)+" ")]):_vm._e(),(_vm.ipetFlg !== '')?_c('div',{staticClass:"ipet",class:{ hide: _vm.grandchild.showFlg === 0 },attrs:{"data-test":"ipet"}},[_vm._v(" "+_vm._s(_vm.ipetFlg)+" ")]):_vm._e(),(!_vm.sortModeFlg)?_c('div',{staticClass:"labels"},[_c('row-labels',{attrs:{"noChangeFlg":_vm.grandchild.noChangeFlg,"showFlg":Boolean(_vm.grandchild.showFlg),"examinationFlg":Boolean(_vm.grandchild.examinationFlg),"noExaminationFlg":Boolean(_vm.grandchild.noExaminationFlg)}})],1):_vm._e()]),(
        !_vm.grandchild.noChangeFlg &&
          !_vm.sortModeFlg &&
          _vm.bottomLayer !== 'grandchild'
      )?_c('div',{staticClass:"buttons"},[(_vm.lookOnlyFlg === 0)?_c('base-button-plus',{staticClass:"add",attrs:{"text":'項目追加',"styles":{ width: '106px' }},on:{"click":_vm.addGreatGrandchild}}):_vm._e(),(_vm.lookOnlyFlg === 0)?_c('base-button-trash',{staticClass:"trash",attrs:{"styles":_vm.buttonStyles},on:{"click":function($event){$event.stopPropagation();return _vm.deleteGrandchild.apply(null, arguments)}}}):_vm._e(),(_vm.lookOnlyFlg === 0)?_c('base-button-small-white',{attrs:{"styles":_vm.buttonStyles},on:{"click":_vm.editGrandchild}},[_vm._v("編集")]):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.bottomLayer !== 'grandchild')?_c('div',{staticClass:"great-grandchildren"},[(_vm.sortModeFlg)?_c('draggable',{model:{value:(_vm.orderGreatGrandchildren),callback:function ($$v) {_vm.orderGreatGrandchildren=$$v},expression:"orderGreatGrandchildren"}},_vm._l((_vm.grandchild.greatGrandchildren),function(greatGrandchild,index){return _c('medicine-detail-list',{key:greatGrandchild.id,attrs:{"medicine":greatGrandchild,"showFlg":_vm.greatGrandchildPanelShowFlg,"isLast":index + 1 === _vm.grandchild.greatGrandchildren.length ? true : false}})}),1):_vm._l((_vm.grandchild.greatGrandchildren),function(greatGrandchild,index){return _c('medicine-detail-list',{key:greatGrandchild.id,attrs:{"medicine":greatGrandchild,"showFlg":_vm.greatGrandchildPanelShowFlg,"isLast":index + 1 === _vm.grandchild.greatGrandchildren.length ? true : false},nativeOn:{"click":function($event){return _vm.selectGreatGrandchild(greatGrandchild.originalId)}}})})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }