<template>
  <div class="slide-list-item">
    <div class="parent" @click="toggleChild">
      <div :class="{ frame: true, sort: sortModeFlg }" data-test="parent">
        <div class="image-allow" :class="{ hide: parent.showFlg === 0 }">
          <img
            class="bottom-allow"
            src="@/assets/images/allow3.png"
            v-show="childPanelShowFlg"
          />
          <img
            class="right-allow"
            src="@/assets/images/allow4.png"
            v-show="!childPanelShowFlg"
          />
        </div>
        <div
          class="name"
          :class="{ hide: parent.showFlg === 0 }"
          :title="parent.name"
        >
          {{ parent.name }}
        </div>
        <div class="labels" v-if="!sortModeFlg">
          <row-labels
            :noChangeFlg="parent.noChangeFlg"
            :showFlg="Boolean(parent.showFlg)"
            :examinationFlg="Boolean(parent.examinationFlg)"
          />
        </div>
      </div>
      <div class="buttons" v-if="!parent.noChangeFlg && !sortModeFlg">
        <base-button-plus
          v-if="lookOnlyFlg === 0"
          class="add"
          :text="'項目追加'"
          :styles="{ width: '106px' }"
          @click="addChild"
        />
        <base-button-trash
          v-if="lookOnlyFlg === 0"
          class="trash"
          :styles="buttonStyles"
          @click.stop="deleteParent"
        />
        <base-button-small-white
          v-if="lookOnlyFlg === 0"
          :styles="buttonStyles"
          @click="editParent"
          >編集</base-button-small-white
        >
      </div>
      <div
        class="buttons"
        v-if="parent.noChangeFlg && parent.plusFlg && !sortModeFlg"
      >
        <base-button-plus
          class="add"
          :text="'項目追加'"
          :styles="{ width: '106px' }"
          @click="addChild"
        />
      </div>
    </div>
    <draggable v-if="sortModeFlg" v-model="orderChildren">
      <slide-list-child
        v-for="(child, index) in orderChildren"
        :key="child.id"
        :childProp="child"
        :bottomLayer="bottomLayer"
        :index="index"
        :childPanelShowFlg="childPanelShowFlg"
        :sortModeFlg="sortModeFlg"
        @order-grandchildren="orderGrandchildren"
        @order-great-grandchildren="orderGreatGrandchildren"
      />
    </draggable>
    <slide-list-child
      v-else
      v-for="child in parent.children"
      :key="child.id"
      :ref="`child${child.originalId}`"
      :childProp="child"
      :parentShowFlg="parent.showFlg"
      :bottomLayer="bottomLayer"
      :childPanelShowFlg="childPanelShowFlg"
      @add-grandchild="addGrandchild"
      @add-great-grandchild="addGreatGrandchild"
      @edit-child="editChild"
      @edit-grandchild="editGrandchild"
      @edit-great-grandchild="editGreatGrandchild"
      @delete-child="deleteChild"
      @delete-grandchild="deleteGrandchild"
      @delete-great-grandchild="deleteGreatGrandchild"
      @select-child="selectChild"
      @select-grandchild="selectGrandchild"
      @select-great-grandchild="selectGreatGrandchild"
    />
  </div>
</template>

<script>
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import RowLabels from '@/components/parts/atoms/RowLabels'
import SlideListChild from '@/components/parts/molecules/SlideListChild'
import Draggable from 'vuedraggable'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import { mapGetters } from 'vuex'

export default {
  name: 'SlideListParent',

  components: {
    BaseButtonPlus,
    BaseButtonTrash,
    BaseButtonSmallWhite,
    RowLabels,
    SlideListChild,
    Draggable
  },

  mixins: [DetectWindowSize],

  props: {
    parent: {
      id: { type: Number },
      originalId: { type: Number },
      name: { type: String },
      showFlg: { type: Number },
      noChangeFlg: { type: Boolean, default: false },
      plusFlg: { type: Boolean, default: false },
      children: { type: Array, default: () => [] }
    },
    bottomLayer: { type: String, default: '' },
    index: { type: Number, default: 0 },
    sortModeFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      childPanelShowFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    orderChildren: {
      get() {
        return this.parent.children
      },
      set(newOrderChildren) {
        this.$emit('order-children', {
          parentIndex: this.index,
          newOrderChildren
        })
      }
    },
    buttonStyles() {
      return this.mixinWindowWidth > 768
        ? { width: '70px', height: '33px' }
        : { width: '44px', height: '33px' }
    }
  },

  methods: {
    toggleChild() {
      this.childPanelShowFlg = !this.childPanelShowFlg
    },
    orderGrandchildren(data) {
      this.$emit('order-grandchildren', { parentIndex: this.index, ...data })
    },
    orderGreatGrandchildren(data) {
      this.$emit('order-great-grandchildren', {
        parentIndex: this.index,
        ...data
      })
    },
    addChild() {
      this.$emit('add-child', this.parent.id)
    },
    addGrandchild(childId) {
      this.$emit('add-grandchild', childId)
    },
    addGreatGrandchild(grandchildId) {
      this.$emit('add-great-grandchild', grandchildId)
    },
    editParent() {
      this.$emit('edit-parent', this.parent.originalId)
    },
    editChild(childOriginalId) {
      this.$emit('edit-child', childOriginalId)
    },
    editGrandchild(grandchildOriginalId) {
      this.$emit('edit-grandchild', grandchildOriginalId)
    },
    editGreatGrandchild(greatGrandchildOriginalId) {
      this.$emit('edit-great-grandchild', greatGrandchildOriginalId)
    },
    deleteParent() {
      this.$emit('delete-parent', this.parent.id)
    },
    deleteChild(childId) {
      this.$emit('delete-child', childId)
    },
    deleteGrandchild(grandchildId) {
      this.$emit('delete-grandchild', grandchildId)
    },
    deleteGreatGrandchild(greatGrandchildId) {
      this.$emit('delete-great-grandchild', greatGrandchildId)
    },
    selectChild(childOriginalId) {
      this.$emit('select-child', childOriginalId)
    },
    selectGrandchild(grandchildId) {
      this.$emit('select-grandchild', grandchildId)
    },
    selectGreatGrandchild(greatGrandchildOriginalId) {
      this.$emit('select-great-grandchild', greatGrandchildOriginalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-list-item {
  > .parent {
    border-bottom: 1px solid #{$very-light-grey};
    display: flex;
    align-items: center;
    height: 55px;
    box-sizing: border-box;
    @include hover();
    > .frame {
      width: 80%;
      display: flex;
      align-items: center;
      > .image-allow {
        width: 16px;
        margin-left: 20px;
        > .bottom-allow {
          width: 15px;
          height: 9px;
        }
        > .right-allow {
          margin-top: 6px;
          width: 9px;
          height: 15px;
        }
      }
      > .name {
        width: 240px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > .labels {
        margin-left: 10px;
      }
    }
    > .sort {
      width: 100%;
      cursor: move;
    }
    > .buttons {
      margin: 0 20px 0 auto;
      display: flex;
      align-items: center;
      gap: 0 10px;
    }
  }
  .hide {
    opacity: 0.3;
  }
}
@media (max-width: $horizontal-tablet-width) {
  .slide-list-item {
    > .parent {
      > .frame {
        > .image-allow {
          margin-left: 10px;
        }
        > .name {
          width: 163px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
