<template>
  <div class="slide-list-child">
    <div class="child" v-if="childPanelShowFlg" @click="clickGrandchild">
      <div :class="{ frame: true, sort: sortModeFlg }" data-test="child">
        <div
          class="image-allow"
          :class="{ hide: childProp.showFlg === 0 }"
          v-if="bottomLayer !== 'child'"
        >
          <img
            class="bottom-allow"
            src="@/assets/images/allow3.png"
            v-show="grandchildPanelShowFlg"
          />
          <img
            class="right-allow"
            src="@/assets/images/allow4.png"
            v-show="!grandchildPanelShowFlg"
          />
        </div>
        <div
          class="name"
          :class="{
            hide: childProp.showFlg === 0,
            margin: bottomLayer === 'child'
          }"
          :title="childProp.name"
        >
          {{ childProp.name }}
        </div>
        <div class="labels" v-if="!sortModeFlg">
          <row-labels
            :noChangeFlg="childProp.noChangeFlg"
            :showFlg="Boolean(childProp.showFlg)"
            :examinationFlg="Boolean(childProp.examinationFlg)"
          />
        </div>
      </div>
      <div
        class="buttons"
        v-if="!childProp.noChangeFlg && !sortModeFlg && bottomLayer !== 'child'"
      >
        <base-button-plus
          v-if="childProp.plusFlg && lookOnlyFlg === 0"
          class="add"
          :text="'項目追加'"
          :styles="{ width: '106px' }"
          @click="addGrandchild"
        />
        <base-button-trash
          v-if="lookOnlyFlg === 0"
          class="trash"
          :styles="buttonStyles"
          @click.stop="deleteChild"
        />
        <base-button-small-white
          v-if="lookOnlyFlg === 0"
          :styles="buttonStyles"
          @click="editChild"
          >編集</base-button-small-white
        >
      </div>
    </div>
    <div class="grandchildren" v-if="bottomLayer !== 'child'">
      <draggable v-if="sortModeFlg" v-model="orderGrandchildren">
        <slide-list-grandchild
          v-for="(grandchild, index) in orderGrandchildren"
          :key="grandchild.id"
          :grandchild="grandchild"
          :bottomLayer="bottomLayer"
          :index="index"
          :grandchildPanelShowFlg="grandchildPanelShowFlg"
          :sortModeFlg="sortModeFlg"
          @order-great-grandchildren="orderGreatGrandchildren"
        />
      </draggable>
      <slide-list-grandchild
        v-else
        v-for="grandchild in childProp.grandchildren"
        :key="grandchild.id"
        :ref="`grandchild${grandchild.originalId}`"
        :grandchild="grandchild"
        :bottomLayer="bottomLayer"
        :grandchildPanelShowFlg="grandchildPanelShowFlg"
        @add-great-grandchild="addGreatGrandchild"
        @edit-grandchild="editGrandchild"
        @delete-grandchild="deleteGrandchild"
        @select-grandchild="selectGrandchild"
        @select-great-grandchild="selectGreatGrandchild"
      />
    </div>
  </div>
</template>

<script>
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import RowLabels from '@/components/parts/atoms/RowLabels'
import SlideListGrandchild from '@/components/parts/molecules/SlideListGrandchild'
import Draggable from 'vuedraggable'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import { mapGetters } from 'vuex'

export default {
  name: 'SlideListChild',

  components: {
    BaseButtonPlus,
    BaseButtonTrash,
    BaseButtonSmallWhite,
    RowLabels,
    SlideListGrandchild,
    Draggable
  },

  mixins: [DetectWindowSize],

  props: {
    // propsDataに 'child' という属性があるとテストでエラーになる。
    // https://www.gitmemory.com/issue/vuejs/vue-test-utils/1798/792325586
    // そのため child ではなく childProp になっている。
    childProp: {
      id: { type: Number },
      originalId: { type: Number },
      name: { type: String, default: '' },
      showFlg: { type: Number },
      noChangeFlg: { type: Boolean, default: false },
      plusFlg: { type: Boolean, default: false },
      grandchildren: { type: Array, default: () => [] }
    },
    index: { type: Number, default: 0 },
    bottomLayer: { type: String, default: '' },
    childPanelShowFlg: { type: Boolean, default: false },
    sortModeFlg: { type: Boolean, default: false },
    parentShowFlg: { type: Number }
  },

  data() {
    return {
      grandchildPanelShowFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    orderGrandchildren: {
      get() {
        return this.childProp.grandchildren
      },
      set(newOrderGrandchildren) {
        this.$emit('order-grandchildren', {
          childIndex: this.index,
          newOrderGrandchildren
        })
      }
    },
    buttonStyles() {
      return this.mixinWindowWidth > 768
        ? { width: '70px', height: '33px' }
        : { width: '44px', height: '33px' }
    }
  },

  watch: {
    childPanelShowFlg: function() {
      if (this.childPanelShowFlg === false) this.grandchildPanelShowFlg = false
    }
  },

  methods: {
    clickGrandchild() {
      if (this.childProp.length === 0) return
      if (this.bottomLayer === 'child') {
        this.$emit('select-child', this.childProp.originalId)
      } else {
        this.grandchildPanelShowFlg = !this.grandchildPanelShowFlg
      }
    },
    orderGreatGrandchildren(data) {
      this.$emit('order-great-grandchildren', {
        childIndex: this.index,
        ...data
      })
    },
    addGrandchild() {
      this.$emit('add-grandchild', this.childProp.id)
    },
    addGreatGrandchild(grandchildId) {
      this.$emit('add-great-grandchild', grandchildId)
    },
    editChild() {
      this.$emit('edit-child', this.childProp.originalId)
    },
    editGrandchild(grandchildOriginalId) {
      this.$emit('edit-grandchild', grandchildOriginalId)
    },
    deleteChild() {
      this.$emit('delete-child', this.childProp.id)
    },
    deleteGrandchild(grandchildId) {
      this.$emit('delete-grandchild', grandchildId)
    },
    selectGrandchild(grandchildId) {
      this.$emit('select-grandchild', grandchildId)
    },
    selectGreatGrandchild(greatGrandchildOriginalId) {
      this.$emit('select-great-grandchild', greatGrandchildOriginalId)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-list-child {
  > .child {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #{$very-light-grey};
    background-color: #{$white_f7};
    height: 55px;
    box-sizing: border-box;
    @include hover();
    > .frame {
      width: 80%;
      display: flex;
      align-items: center;
      > .image-allow {
        width: 16px;
        margin-left: 30px;
        > .bottom-allow {
          width: 15px;
          height: 9px;
        }
        > .right-allow {
          margin-top: 6px;
          width: 9px;
          height: 15px;
        }
      }
      > .name {
        width: 240px;
        margin-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > .margin {
        margin-left: 35px;
      }
      > .labels {
        margin-left: 10px;
      }
    }
    > .sort {
      width: 100%;
      cursor: move;
    }
    > .buttons {
      margin: 0 20px 0 auto;
      display: flex;
      align-items: center;
      gap: 0 10px;
    }
  }
  .hide {
    opacity: 0.3;
  }
}
@media (max-width: $horizontal-tablet-width) {
  .slide-list-child {
    > .child {
      > .frame {
        > .image-allow {
          margin-left: 20px;
        }
        > .name {
          width: 153px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
